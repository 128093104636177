import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Observable, filter, finalize, map, shareReplay, switchMap, tap } from "rxjs";
import { environment } from "src/environments/environment";

import { UserModel } from "../model/user.model";
import { GeneralService } from "../service/general.service";
import { ApiService } from "./api.service";
import { UserEnum } from "../enum/user.enum";

export class UserBasicInfo {
  id: number;
  userType: UserEnum;
  salesRepId: number;
  emaryse: number;
}

@Injectable({
  providedIn: "root",
})
export class UserDAOService {
  token: string;
  pageName = "users";
  lang: string;
  screenType = /Android|webOS|iPhone/i.test(navigator.userAgent) ? "mobile" : "laptop";
  country;
  private loadingUser: boolean = false;
  private userBasicSubject = new BehaviorSubject<UserBasicInfo>(JSON.parse(localStorage.getItem('User_Basic_Info')));
  userBasic$ = this.userBasicSubject.asObservable()
  get userBasic() {
    return this.userBasicSubject.getValue()
  }
  private userSubject = new BehaviorSubject<UserModel>(null);

  user$ = this.userSubject.asObservable().pipe(switchMap(user => {
    if (!user && !this.loadingUser) {
      this.loadingUser = true
      return this.getUser().pipe(finalize(() => { this.loadingUser = false }))
    }
    return this.userSubject.asObservable()
  }), filter((user) => user ? true : false));

  constructor(
    private api: ApiService,
    private http: HttpClient,
    private generalService: GeneralService,
    private router: Router
  ) {

    // this.autoLogin()
    this.lang = this.generalService.getCurrentLang();

    this.country = this.router.url.toString().toLocaleLowerCase().includes("ae") ? "ae" : "eg";
  }


  setUserData(user) {
    this.userSubject.next(user);
    this.saveUserBasicInfo(user)
  }
  saveUserBasicInfo({ id, userType, salesRepId, emaryse }: UserBasicInfo) {
    if (this.userBasic?.id != id) {
      this.userBasicSubject.next({ id, userType, salesRepId, emaryse })
      localStorage.setItem('User_Basic_Info', JSON.stringify({ id, userType, salesRepId, emaryse }))
    }

  }

  autoLogin() {
    const token = localStorage.getItem("token");
    const helper = new JwtHelperService();
    if (!token || helper.isTokenExpired(token)) {
      localStorage.removeItem("token");
      localStorage.removeItem("isLoggedIn");
      return;
    }
    this.token = JSON?.parse(token || "");
  }
  addUser(newData: any): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/adduser`, newData);
  }
  updateUser(newData: any): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/updateUser`, newData);
  }
  getDropdowns(): Observable<Object> {
    return this.api.getRequest<any>(`${this.pageName}/lookups`);
  }

  uploadAttachment(formData: any): Observable<Object> {
    return this.api.postRequestAttachment<Object>("attachments/id", formData);
  }
  getAttachment(): Observable<Object> {
    return this.api.getRequest<Object>("attachments/id");
  }

  login(newData: any): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/login`, newData).pipe(
      tap((token: any) => {
        this.handleAuthentication(token["jwt"]);
      })
    );
  }

  private handleAuthentication(token: string) {
    this.token = token
    localStorage.setItem("token", JSON.stringify(token));
  }



  socialLogin(newData: any, token: string): Observable<Object> {
    //return this.api.postRequest<Object>(`${this.pageName}/socialLogin`, newData);
    const headers = new HttpHeaders({
      token: token,
    });
    const params = new HttpParams().set("token", token);
    return this.http.post(`${environment.backendUrl}/${this.pageName}/socialLogin`, newData, { headers: headers, params: params }).pipe(
      tap((token: any) => {
        this.handleAuthentication(token["jwt"]);
      })
    );
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("isLoggedIn");
    const masterValue = localStorage.getItem("master");
    localStorage.setItem("master", String(masterValue));
    localStorage.clear();
    this.router.navigateByUrl(`${this.lang}`);
    this.api
      .postRequest<Object>(`${this.pageName}/logout`, {})
      .subscribe(() => {
        window.location.reload();
      });
  }

  fullRegister(user: UserModel): Observable<Object> {
    //return this.api.postRequest<Object>(`${this.pageName}/getUser`, { username: userName })
    return this.api.postRequest<Object>(`${this.pageName}/fullRegister`, user)
  }

  getUser(): Observable<UserModel> {
    return this.api.postRequest<Object>(`${this.pageName}/getUser`, {}).pipe(
      tap((user: any) => {
        this.userSubject.next(user)
        this.saveUserBasicInfo(user);
      }),
    );
  }

  getUserId(userId: number): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/getUser?userId=${userId}`, {});
  }
  getUserEmail(userEmail: string): Observable<Object> {
    return this.api.getRequest<Object>(`users/getUserByMail?mail=${userEmail}`);
  }

  forgetPassword(newData: any): Observable<Object> {
    return this.api.postRequest<Object>("password/reset", newData)
  }

  sendForgetPasswordLink(mail: string) {
    return this.api.postRequest<Object>("password/checkEmailExist", { mail: mail })
  }

  sendVerifyEmail(): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/sendVerifyEmail`, {})
  }

  verifyEmail(token: string): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/verifyEmail`, token)
  }

  getPosition(): Observable<Object> {
    return this.api.postRequest<Object>(`${this.pageName}/getMyPosition`, {})
  }

  getHistory(): Observable<Object> {
    const UID = this.userBasic.id ?? null;
    return this.api.getRequest<any>(`histories/historiesByUsrId?userId=${UID}&page=0&size=12`);
  } //mmostafa


  deleteOneUnitOfHistory(id: number): Observable<Object> {
    return this.api.deleteRequest<Object>(`/histories/${id}`)
  }

  deleteOneUnitOComparison(id: number): Observable<Object> {
    //id,{arrayOfUnits without id for each unit} so  we can delete the whole comparison but we can't delete a unit from a comparison itself
    return this.api.deleteRequest<Object>(`comparisons/search/findByUserIdGroup?userId=${id}}`)
  }

  getComparison() {
    const UID = this.userBasic.id ?? null;
    return this.api
      .getRequest<Object>(`comparisons/comparisonsByUsrId?userId=${UID}`)
  }

  deleteComparison(id: any) {
    return this.api.deleteRequest<Object>(`comparisons?comparisonId=${id}`)
  }

  addSpecialRequest(request) {
    return this.api.postRequest<any>("Special/customRequest", request);
  }


  saveComparison(ids: string[]): Observable<any> {
    return this.api.postRequest<any>(`${this.pageName}/createUserComp`, { mixedIds: ids })
  }

  getIpAddress() {
    return this.api.getIpRequest<any>()
  }

  /// for counting whats app and caller clicks
  userWhatsAppCounter() {
    this.api.getRequest<Object>(`${this.pageName}/usrWhatsAppCounter`).subscribe();
  }
  usrCallCounter() {
    this.api.getRequest<Object>(`${this.pageName}/usrCallCounter`).subscribe();
  }
  getRequestRegions() {
    return this.api.getRequest<any>(`locations/getAllRegions`);
  }
}
