import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { take } from "rxjs";
import { GeneralService } from "src/app/core/service/general.service";
import { CurrencyService } from "src/app/shared/services/currency/currency.service";
import { UserDAOService } from "src/app/core/api/user-dao.service";
@Component({
  selector: "app-countries-dropdown",
  templateUrl: "./countries-dropdown.component.html",
  styleUrls: ["./countries-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountriesDropdownComponent implements OnInit {
  countries = [
    {
      name: "egy",
      id: "eg",
    },
    // {
    //   name: "uae",
    //   id: "ae",
    // },
  ];
  selectedCountry;
  isProd = true;
  constructor(private userService: UserDAOService, private currencyService: CurrencyService, private generalService: GeneralService) {
    this.getCountry();
  }

  ngOnInit(): void {}
  getCountry() {
    this.selectedCountry = this.countries.find((c) => c.id == this.userService.country);
  }
  countryChange(e) {
    if (e.id == "ae") {
      this.isProd
        ? window.location.replace(`https://eshtriaqar.com.ae/${this.generalService.getCurrentLang()}`)
        : window.location.replace(
            `https://pre-dev-revamp-ae.k8s-cluster-poc-475abba301f29ce035eb2a3d8e891717-0000.eu-de.containers.appdomain.cloud/${this.generalService.getCurrentLang()}`
          );
    } else {
      this.isProd
        ? window.location.replace(`https://eshtriaqar.com.eg/${this.generalService.getCurrentLang()}`)
        : window.location.replace(
            `https://pre-dev-revamp.k8s-cluster-poc-475abba301f29ce035eb2a3d8e891717-0000.eu-de.containers.appdomain.cloud/${this.generalService.getCurrentLang()}`
          );
    }
  }
  setCurrency() {
    const selectedCurrency = this.currencyService.getSelectedCurrency();
    if (selectedCurrency == null) {
      let currency;
      this.currencyService
        .getCurrencies()
        .pipe(take(1))
        .subscribe((res) => {
          currency = res.find((c) => c.name == "AED");
          this.currencyService.updateCurrency(currency);
        });
    }
  }
}
