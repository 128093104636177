import { Injectable } from "@angular/core";

import { ApiService } from "src/app/core/api/api.service";
import { GlobalDAOService } from "src/app/core/api/global-dao.service";

@Injectable({
  providedIn: "root",
})
export class OffersApiService extends GlobalDAOService<any> {
  page = "offersPage";

  constructor(protected api: ApiService) {
    super(api);
  }
  getHeader() {
    return this.api.getRequest<any>(`${this.page}/offers`);
  }
  offerByCategory(cat = 3) {
    return this.api.getRequest<any>(`${this.page}/offerByCategory?catId=${cat}`);
  }
}
