import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";

import { Observable } from "rxjs";

import { StorageService } from "../service/utilities/local-storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class GuestGuard implements CanActivate {
  constructor(private location: Location, private storageService: StorageService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>((resolve, reject) => {
      if (this.storageService.getItem("isLoggedIn") == "true") {
        reject(false);
        this.location.back();
      } else {
        resolve(true);
      }
    });
  }
}
