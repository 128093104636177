<div class="flag-currency" *rxIf="currencies$; let currencies">
  <mat-form-field appearance="fill">
    <mat-select (valueChange)="handleChange($event)" [value]="currentExchange.flag">
      <mat-select-trigger>
        <!-- <span class="flag-icon flag-icon-squared rounded-flag flag-icon-{{ currentExchange.flag }} "></span> -->
        <mat-icon svgIcon="currency"></mat-icon>
        <span> {{ "currency." + currentExchange.name | translate }} </span>
      </mat-select-trigger>
      <mat-option *ngFor="let currency of currencies" [value]="currency.flag">
        <span class="flag-icon flag-icon-squared rounded-flag flag-icon-{{ currency.flag }} "></span>
        <span> {{ "currency." + currency.name | translate }} </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>