export const iconsList = [
  {
    url: "/assets/icon/currency.svg",
    name: "currency",
  },
  {
    url: "/assets/icon/whatsapp.svg",
    name: "whatsapp",
  },
  {
    url: "/assets/icon/chat.svg",
    name: "chat",
  },
  {
    url: "/assets/icon/calculator.svg",
    name: "calculator",
  },
  {
    url: "/assets/icon/zoom.svg",
    name: "zoom",
  },
  {
    url: "/assets/icon/offer.svg",
    name: "offer",
  },
  {
    url: "/assets/icon/offer-rounded.svg",
    name: "offer-rounded",
  },
  {
    url: "/assets/icon/home-shape.svg",
    name: "home-shape",
  },
  {
    url: "/assets/icon/chair.svg",
    name: "chair",
  },
  {
    url: "/assets/icon/commercial.svg",
    name: "commercial",
  },
  {
    url: "/assets/icon/side-bed.svg",
    name: "side-bed",
  },
  {
    url: "/assets/icon/location.svg",
    name: "location",
  },
  {
    url: "/assets/icon/location-filled.svg",
    name: "location-filled",
  },
  {
    url: "/assets/icon/phone-filled.svg",
    name: "phone-filled",
  },
  {
    url: "/assets/icon/facebook.svg",
    name: "facebook",
  },
  {
    url: "/assets/icon/linkedin.svg",
    name: "linkedin",
  },
  {
    url: "/assets/icon/youtube.svg",
    name: "youtube",
  },
  {
    url: "/assets/icon/instagram.svg",
    name: "instagram",
  },
  {
    url: "/assets/icon/bank-ahly.svg",
    name: "bank-ahly",
  },
  {
    url: "/assets/icon/fawry.svg",
    name: "fawry",
  },
  {
    url: "/assets/icon/discount.svg",
    name: "discount",
  },
  {
    url: "/assets/icon/reservation.svg",
    name: "reservation",
  },
  {
    url: "/assets/icon/developers.svg",
    name: "developers",
  },
  {
    url: "/assets/icon/city.svg",
    name: "city",
  },
  {
    url: "/assets/icon/houses.svg",
    name: "houses",
  },
  {
    url: "/assets/icon/calender-check.svg",
    name: "calender-check",
  },
  {
    url: "/assets/icon/time-left.svg",
    name: "time-left",
  },
  {
    url: "/assets/icon/file.svg",
    name: "file",
  },
  {
    url: "/assets/icon/city-1.svg",
    name: "city-1",
  },
  {
    url: "/assets/icon/hand-shake.svg",
    name: "hand-shake",
  },
  {
    url: "/assets/icon/phone.svg",
    name: "phone",
  },
  {
    url: "/assets/icon/love.svg",
    name: "love",
  },
  {
    url: "/assets/icon/love-filled.svg",
    name: "love-filled",
  },
  {
    url: "/assets/icon/share.svg",
    name: "share",
  },
  {
    url: "/assets/icon/bedroom.svg",
    name: "bedroom",
  },
  {
    url: "/assets/icon/bathroom.svg",
    name: "bathroom",
  },
  {
    url: "/assets/icon/floor.svg",
    name: "floor",
  },
  {
    url: "/assets/icon/area.svg",
    name: "area",
  },
  {
    url: "/assets/icon/key.svg",
    name: "key",
  },
  {
    url: "/assets/icon/adib-installment.svg",
    name: "adib-installment",
  },
  {
    url: "/assets/icon/adib-license.svg",
    name: "adib-license",
  },
  {
    url: "/assets/icon/new-email.svg",
    name: "new-email",
  },
  {
    url: "/assets/icon/email.svg",
    name: "email",
  },
  {
    url: "/assets/icon/add-favorite.svg",
    name: "add-favorite",
  },
  {
    url: "/assets/icon/monthly-down.svg",
    name: "monthly_down",
  },
  {
    url: "/assets/icon/monthly-up.svg",
    name: "monthly_up",
  },
  {
    url: "/assets/icon/price-down.svg",
    name: "price_down",
  },
  {
    url: "/assets/icon/price-up.svg",
    name: "price_up",
  },
  {
    url: "/assets/icon/area-down.svg",
    name: "area_down",
  },
  {
    url: "/assets/icon/area-up.svg",
    name: "area_up",
  },
  {
    url: "/assets/icon/delivery-down.svg",
    name: "delivery_down",
  },
  {
    url: "/assets/icon/delivery-up.svg",
    name: "delivery_up",
  },
  {
    url: "/assets/icon/add-rounded.svg",
    name: "add-rounded",
  },
  {
    url: "/assets/icon/garden.svg",
    name: "garden",
  },
  {
    url: "/assets/icon/terrace.svg",
    name: "terrace",
  },
  {
    url: "/assets/icon/info.svg",
    name: "info",
  },
  {
    url: "/assets/icon/warning.svg",
    name: "warning",
  },
  {
    url: "/assets/icon/AC.svg",
    name: "AC",
  },
  {
    url: "/assets/icon/dressing-room.svg",
    name: "dressing-room",
  },
  {
    url: "/assets/icon/garage.svg",
    name: "garage",
  },
  {
    url: "/assets/icon/land-area.svg",
    name: "land-area",
  },
  {
    url: "/assets/icon/club.svg",
    name: "club",
  },
  {
    url: "/assets/icon/roof.svg",
    name: "roof",
  },
  {
    url: "/assets/icon/empty-set.svg",
    name: "empty-set",
  },
  {
    url: "/assets/icon/calender.svg",
    name: "calender",
  },
  {
    url: "/assets/icon/person-location.svg",
    name: "person-location",
  },
  {
    url: "/assets/icon/calender-time.svg",
    name: "calender-time",
  },
  {
    url: "/assets/icon/check-mark.svg",
    name: "check-mark",
  },
  {
    url: "/assets/icon/clock.svg",
    name: "clock",
  },
  {
    url: "/assets/icon/file-check.svg",
    name: "file-check",
  },
  {
    url: "/assets/icon/play.svg",
    name: "play",
  },
  {
    url: "/assets/icon/bank-ahly-colored.svg",
    name: "bank-ahly-colored",
  },
  {
    url: "/assets/icon/play-store.svg",
    name: "play-store",
  },
  {
    url: "/assets/icon/apple-store.svg",
    name: "apple-store",
  },
];
