import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";

import { LoadingService } from "../service/loading.service";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private loadingService: LoadingService, private spinnerService: NgxSpinnerService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.includes("getMyPositionNew") && !request.url.includes("getSalesManScheduledApointments"))
      this.loadingService.loadingOn();
    return next.handle(request).pipe(
      catchError((error) => {
        setTimeout(() => {
          this.spinnerService.hide();
        }, 900);
        return throwError(error);
      }),
      finalize(() => {
        if (
          !request.url.includes("getMyPositionNew") &&
          !request.url.includes("getSalesManScheduledApointments") &&
          !request.url.includes("getUser") &&
          !request.url.includes("bath")
        ) {
          setTimeout(() => {
            this.spinnerService.hide();
          }, 900);
          this.loadingService.loadingOff();
        }
      })
    );
  }
}
