import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { ApiService } from "src/app/core/api/api.service";
import { CompareParams } from "src/app/shared/dto/compare/compareBody.interface";
import { UnitComparison } from "src/app/shared/dto/compare/UnitComparison.interface";
import { Unit } from "src/app/shared/dto/Unit.interface";

@Injectable({
  providedIn: "root",
})
export class UnitDTOService {
  pageName = "units";
  constructor(private api: ApiService) {
  }

  getUnit(id: number): Observable<Unit> {
    return this.api.getRequest<Unit>(`${this.pageName}/unitDetails?unitId=${id}`);
  }
  unitComparison(ids: CompareParams): Observable<UnitComparison> {
    return this.api.getRequestParams<UnitComparison>(`${this.pageName}/compare`, ids);
  }

  showContractPDF(compCode: number, devCode: number, modCode: string): Observable<Object> {
    return this.api
      .getRequest<Object>(`${this.pageName}/downloadModelPlans?compCode=${compCode}&devCode=${devCode}&modCode=${modCode}`)
      ;
  }

  getUnitImages(unitId: number): Observable<Object> {
    return this.api.getRequest<Object>(`${this.pageName}/unitSliderXxl?unitId=${unitId}`);
  }
  getSearchPopup(unitId: number): Observable<Object> {
    return this.api.getRequest<Object>(`${this.pageName}/unitSlider?unitId=${unitId}`);
  }
  getPlansImages(unitId: number): Observable<Object> {
    return this.api.getRequest<Object>(`${this.pageName}/unitPlan?unitId=${unitId}`);
  }

  getUnitinfo(id: number): Observable<Unit> {
    return this.api.getRequest<Unit>(`${this.pageName}/unitDetails/info?unitId=${id}`);
  }
  getUnitSlug(unitId: number, regId: number, usgId: number, compId: number, devId: number): Observable<Unit> {
    return this.api
      .getRequest<Unit>(`${this.pageName}/unitDetails/slug?unitId=${unitId}&regId=${regId}&usgId=${usgId}&compId=${compId}&devId=${devId}`)
      ;
  }
  getUnitMedia(unitId: number, modId: number, flrId: number, compId: number, secId: number): Observable<Unit> {
    return this.api
      .getRequest<Unit>(`${this.pageName}/unitDetails/media?unitId=${unitId}&modId=${modId}&flrId=${flrId}&compId=${compId}&secId=${secId}`)
      ;
  }
  getUnitViewandInfra(unitId: number, compId: number): Observable<Unit> {
    return this.api.getRequest<Unit>(`${this.pageName}/unitDetails/viewAndInfra?unitId=${unitId}&compId=${compId}`);
  }
}
