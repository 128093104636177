import { Injectable } from "@angular/core";

import { shareReplay } from "rxjs/operators";
import { FilterQuery } from "src/app/shared/dto/UniqueFilter.interface";

import { ApiService } from "./api.service";
import { GlobalDAOService } from "./global-dao.service";
import { UserDAOService } from "./user-dao.service";

@Injectable({
  providedIn: "root",
})

// To get units that are not related to listing page
export class InPageSearch extends GlobalDAOService<any> {
  constructor(api: ApiService, private userService: UserDAOService) {
    super(api);
  }

  // Takes dynamic search criteria based on the FilterQuery interface
  // deletes null or undefined values and then send the request to get units
  getFilteredUnits(search: FilterQuery) {
    let searchCriteria: FilterQuery;
    const user = this.userService.userBasic
    searchCriteria = {
      config: {
        pageNumber: 1,
        itemCount: 12,
        lang: 1,
        user_id: user.id + '',
        user_type: user.userType + '',
      },
      query: {
        balcony: [],
        developers_ids: [],
        locations_ids: [],
        compounds_ids: [],
        category_txt: [],
        type_ids: [],
        unitSize_range: [],
        bedrooms: [],
        bathrooms: [],
        floors: [],
        price_range: [],
        finishing: [],
        reservationAmount_range: [],
        monthlyInstallment_range: [],
        paymentPlan_range: [],
        deliveryDate: [],
        garage: [],
        media: [],
        ac: [],
      },
    };
    Object.keys(search).forEach((key) => {
      if (search["config"][key] != null && search["config"][key] != undefined) {
        searchCriteria["config"][key] = search["config"][key];
      }
      if (search["query"]) {
        if (search["query"][key] != null && search["query"][key] != undefined) {
          searchCriteria["query"][key] = search["query"][key];
        }
      }
    });
    //Khaled : padding searchCriteria  not changing queryParams sent in header's request
    return this.api.postRequest("find", search).pipe(
      shareReplay()
      // tap((res) => ))
    );
  }
}
