import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { HotToastService } from "@ngneat/hot-toast";
import { GeneralService } from "src/app/core/service/general.service";
import { PullBackService } from "src/app/sales-man/service/pull-back.service";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"]
})
export class FeedbackComponent implements OnInit {
  viewPopup = false;
  selectedReview;
  list;
  answerText = "";
  showAll = false;
  inside = true;
  disableBtn = false;
  lang: string;
  constructor(private PullBackService: PullBackService, private toasterService: HotToastService, private generalService: GeneralService) { }

  ngOnInit(): void {
    this.lang = this.generalService.getCurrentLang();
    this.getList();
  }
  getList() {
    this.PullBackService.getSurveys().subscribe((res: any) => {
      this.list = res?.filter((s) => s.surveyType == 3);
    });
    setTimeout(() => {
      this.showAll = true;
    }, 60000);
  }
  clickOut(e: any) {
    let p = e.path.find((c) => {
      return c.className?.includes("feedback");
    });
    if (!p) {
      this.viewPopup = false;
    }
  }
  submit() {
    this.disableBtn = true;
    let body = { surveyTypeId: 3, surveyReasonId: this.selectedReview, answerText: this.answerText };
    this.PullBackService.setSurvey(body).subscribe((res) => {
      this.toasterService.success(this.lang != "en" ? "شكراً على تعاونكم معنا" : "Thank you for your feedback.");
      this.viewPopup = false;
      this.showAll = false;
    });
  }
}
