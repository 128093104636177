import { Injectable, OnDestroy } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Params, Router } from "@angular/router";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";

import { combineLatest } from "rxjs";
import { RouteParams } from "src/app/shared/dto/Route.interface";

@Injectable({
  providedIn: "root",
})
export class GeneralService implements OnDestroy {
  private currentLang: string;

  constructor(localizedRouterService: LocalizeRouterService, private router: Router, private route: ActivatedRoute) {
    this.currentLang = localizedRouterService.parser.currentLang;
  }
  ngOnDestroy(): void {}

  getCurrentLang(): string {
    return this.currentLang;
  }

  navigateToWithParams(route: string, extra?: NavigationExtras): void {
    this.router.navigate([`/${this.currentLang + route}`], extra).then();
  }

  // get route params and queryParams from input route
  getRouteParams(route: ActivatedRoute): RouteParams {
    let parameters: Params;
    let queryParameters: Params;
    combineLatest([route.params, route.queryParams]).subscribe(([params, queryParams]) => {
      parameters = params;
      queryParameters = queryParams;
    });
    return { params: parameters, queryParams: queryParameters };
  }
}
