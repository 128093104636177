<div class="wrapper" *ngIf="showAll" [ngStyle]="{ 'margin-inline-end': !viewPopup ? '0' : '3rem' }" id="feedback"
  [ngClass]="{ ar: lang != 'en' }">
  <div class="main-icon feedback" *ngIf="!viewPopup" (click)="viewPopup = true">
    <img alt="" src="../../../../assets/svg/Group 4800.svg" />
    <div class="text-wrapper feedback">
      <label>{{ "feedback.title" | translate }}</label>
    </div>
  </div>
  <div class="content-section" *ngIf="viewPopup && list" (clickOutside)="clickOut($event)">
    <div class="icons-section">
      <div class="close">
        <mat-icon (click)="viewPopup = false">close</mat-icon>
      </div>
      <p>{{ "feedback.add" | translate }}</p>
      <mat-radio-group [(ngModel)]="selectedReview" fxLayout="row" fxLayoutAlign="center center" fxFill class="options">
        <mat-radio-button [value]="list[0].surveyReasonId">
          <img alt="" src="../../../../assets/svg/happy.svg"
            [class.active]="selectedReview == list[0].surveyReasonId" /><span
            *ngIf="selectedReview == list[0].surveyReasonId">{{ list[0].surveyReasonText | titlecase }}</span>
        </mat-radio-button>
        <mat-radio-button [value]="list[1].surveyReasonId"><img alt="" src="../../../../assets/svg/smile.svg"
            [class.active]="selectedReview == list[1].surveyReasonId" /><span
            *ngIf="selectedReview == list[1].surveyReasonId">{{ list[1].surveyReasonText | titlecase
            }}</span></mat-radio-button>
        <mat-radio-button [value]="list[2].surveyReasonId"><img alt="" src="../../../../assets/svg/confused.svg"
            [class.active]="selectedReview == list[2].surveyReasonId" /><span
            *ngIf="selectedReview == list[2].surveyReasonId">{{ list[2].surveyReasonText | titlecase
            }}</span></mat-radio-button>
        <mat-radio-button [value]="list[3].surveyReasonId"><img alt="" src="../../../../assets/svg/bored.svg"
            [class.active]="selectedReview == list[3].surveyReasonId" /><span
            *ngIf="selectedReview == list[3].surveyReasonId">{{ list[3].surveyReasonText | titlecase
            }}</span></mat-radio-button>
        <mat-radio-button [value]="list[4].surveyReasonId"><img alt="" src="../../../../assets/svg/sad.svg"
            [class.active]="selectedReview == list[4].surveyReasonId" /><span
            *ngIf="selectedReview == list[4].surveyReasonId">{{ list[4].surveyReasonText | titlecase
            }}</span></mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="message-section" [class.show]="selectedReview">
      <textarea [placeholder]="'feedback.comment' | translate" [(ngModel)]="answerText"></textarea>
      <button class="gbtn gbtn-flat primary pill" color="primary" (click)="submit()" [disabled]="disableBtn">{{
        "feedback.submit" |
        translate }}</button>
    </div>
  </div>
</div>