import { Location } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule, BrowserTransferStateModule, TransferState } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from "@gilsdav/ngx-translate-router";
import { HotToastModule } from "@ngneat/hot-toast";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { NgxHotjarModule, NgxHotjarRouterModule } from "ngx-hotjar";
import { environment } from "src/environments/environment";
import { routes } from "../app-routing.module";

import { ErrorInterceptor } from "./interceptor/error-interceptor/error.interceptor";
import { JwtInterceptor } from "./interceptor/jwt.interceptor";
import { LoadingInterceptor } from "./interceptor/loading.interceptor";
import { localizeBrowserLoaderFactory } from "./utils/localize-browser.loader";
import { translateBrowserLoaderFactory } from "./utils/translate-browser.loader";
import { MainLayoutModule } from "./layout/main-layout.module";

@NgModule({
  declarations: [],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: Window, useValue: window },
  ],
  imports: [
    MainLayoutModule,
    RouterModule,
    TranslateModule.forRoot({
      // defaultLanguage: "en",
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeBrowserLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient, TransferState],
      },
      initialNavigation: true,
    }),
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    HttpClientModule,
    HotToastModule.forRoot(),
    NgxHotjarModule.forRoot(environment.hotjar),
    NgxHotjarRouterModule,
  ],

  exports: [MainLayoutModule],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule { }
