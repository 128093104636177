import { Injectable } from "@angular/core";

import { BehaviorSubject, interval } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadingService {
  loading$: BehaviorSubject<Boolean>;
  seconds: number;
  subscription: any;

  constructor() {
    this.loading$ = new BehaviorSubject(false);
  }
  loadingOn() {
    return this.loading$.next(true);
  }
  loadingOff() {
    return this.loading$.next(false);
  }
  isLoading() {
    return this.loading$.asObservable();
  }
  setSpinnerTimer() {
    this.seconds = 10;
    this.subscription = interval(100).subscribe((x) => {
      var bar = document.getElementById("bar");
      if (this.seconds != 100) {
        let newWidth = this.seconds + 1;
        this.seconds = newWidth;
        if (bar) bar.style.width = newWidth.toString() + "%";
      }
    });
  }
}
