import { Injectable } from "@angular/core";

import { BehaviorSubject, filter } from "rxjs";
import { io } from "socket.io-client";
import { UserModel } from "src/app/core/model/user.model";
import { environment } from "src/environments/environment";

import { SalesManStatusModel } from "../salesman-status";
import { SalesmanService } from "./salesman.service";
import { UserDAOService } from "src/app/core/api/user-dao.service";
import { UserEnum } from "src/app/core/enum/user.enum";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  socket$ = new BehaviorSubject<any>(null);
  status$ = new BehaviorSubject<number>(null);
  isUserSalesMan$ = new BehaviorSubject<boolean>(false);
  serverWork$ = new BehaviorSubject<boolean>(false);

  callDetails$ = new BehaviorSubject<{
    callName: string;
    userId: number;
    callNumber: string;
    whatsappNumber: string;
    appoitnmentType: number;
    appoitmentMethod: number;
    appointmentId: number;
    timerInMinutes: number;
    startUrl: string;
    compId: number;
    unitId: number;
    devId: number;
  }>({
    callName: null,
    appoitmentMethod: null,
    appoitnmentType: null,
    callNumber: null,
    whatsappNumber: null,
    userId: null,
    appointmentId: null,
    timerInMinutes: null,
    startUrl: null,
    compId: null,
    unitId: null,
    devId: null,
  });

  constructor(private userService: UserDAOService, private salesmanService: SalesmanService) { }

  connect(id: number) {
    this.socket$.next(
      io(environment.socketIoUrl, {
        path: "/socket",
      })
    );

    this.socket$.subscribe((socket) => {
      socket.on("connect", () => {
        this.serverWork$.next(true);
        socket.emit("room", id, (msg: any) => { });
      });
      socket.on("recieve-status", (status) => {
        this.status$.next(status);
      });
      socket.on("recieve-notification", (msg) => {
        this.salesmanService.getSalesmanStatus().subscribe((res: SalesManStatusModel) => {
          this.status$.next(res.status);
          this.callDetails$.next({
            callName: res.message,
            appointmentId: res.appointId,
            appoitmentMethod: res.appointmentMethod,
            appoitnmentType: res.appointmentTypeId,
            callNumber: res.mobile,
            userId: res.usrId,
            timerInMinutes: res.timerInMinutes,
            whatsappNumber: res.whatsappNumber,
            startUrl: res.startUrl,
            compId: res.compId,
            unitId: res.unitId,
            devId: res.devId,
          });
          this.callDetails$.subscribe((res) => { });
        });
      });
      socket.on("disconnect", () => {
        this.serverWork$.next(false);
        window.location.reload();
      });
    });
  }

  salesmanStatus(status: number) {
    this.userService.user$.pipe(filter((user) => user.userType == UserEnum.EMPLOYEE))
      .subscribe((user: UserModel) => {
        this.socket$.subscribe((socket) => {
          socket.emit("salesman-status", user.salesRepId, status);
        });
      });
  }
}
