import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { delay, shareReplay } from "rxjs";

import { Observable } from "rxjs";
import { ApiService } from "src/app/core/api/api.service";
import { GlobalDAOService } from "src/app/core/api/global-dao.service";
import { UserDAOService } from "src/app/core/api/user-dao.service";
import { GeneralService } from "src/app/core/service/general.service";

@Injectable({
  providedIn: "root",
})
export class HomeApiService {
  page = "home";
  promoHeader$: Observable<any>;
  screenType = /Android|webOS|iPhone/i.test(navigator.userAgent) ? "mobile" : "laptop";
  country;
  lang;
  constructor(protected api: ApiService, private route: Router) {
    // super(api);

    this.country = this.route.url.toString().toLocaleLowerCase().includes("ae") ? "ae" : "eg";
    this.lang = localStorage.getItem("LOCALIZE_DEFAULT_LANGUAGE");
  }
  getLaunchByCategory(cat = 3): Observable<any> {
    return this.api.getRequest<any>(`${this.page}/launchesByCategory/${this.lang}/${this.country}/${this.screenType}?catId=${cat}`);
  }
  getLocationsByCategory(cat = 1): Observable<any> {
    return this.api.getRequest<any>(`${this.page}/regionsByCategory/${this.lang}/${this.country}/${this.screenType}?catId=${cat}`);
  }
  getCompoundByCategoryAndLocation(cat = 1, locationId = 1): Observable<any> {
    return this.api.getRequest<any>(
      `${this.page}/compoundsByCategoryAndRegion/${this.lang}/${this.country}/${this.screenType}?regId=${locationId}&catId=${cat}`
    );
  }
  getPromoHeader(lang: string): Observable<any> {
    if (!this.promoHeader$)
      this.promoHeader$ = this.api
        .getRequest<any>(`${this.page}/promoHeader/${this.lang}/${this.country}/${this.screenType}`)
        .pipe(shareReplay(1));
    return this.promoHeader$;
  }
  getOffers(catId): Observable<any> {
    return this.api.getRequest<any>(`${this.page}/offer/${this.lang}/${this.country}/${this.screenType}?catId=${catId}`);
  }
  getDiscoverList() {
    return this.api.getRequest<any>(`${this.page}/discover/${this.lang}/${this.country}/${this.screenType}`);
  }

}
