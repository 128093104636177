import { UserModel } from "./../../../model/user.model";
import { UserDAOService } from "./../../../api/user-dao.service";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { SocialAuthService } from "@abacritt/angularx-social-login";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { BehaviorSubject } from "rxjs";
import { LazyLoadingPopupData, LazyLoadingPopupComponent } from "src/app/features/lazy-loading-popup/lazy-loading-popup.component";
import { HomeApiService } from "src/app/pages/home/api/home-api.service";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  userData: UserModel;
  discoverList = new BehaviorSubject<any>(null);
  constructor(
    private dialog: MatDialog,
    private authService: SocialAuthService,
    private userDAO: UserDAOService,
    private homeApi: HomeApiService,
    private localizeRouterService: LocalizeRouterService
  ) {
    this.changeLang();
  }

  loginSignupTrigger() {
    const dialogData: LazyLoadingPopupData = {
      import: import("src/app/features/login-signup-popup/login-signup-popup.component"),
      component: "LoginSignupPopupComponent",
    };

    return this.dialog.open(LazyLoadingPopupComponent, { data: dialogData, panelClass: "dialog_radius-1" });
  }
  logOut() {
    this.authService.signOut();
    this.userDAO.logout();
  }
  getDiscoverList() {
    return this.homeApi.getDiscoverList();
  }
  changeLang(lang?: string) {
    if (lang) {
      if (lang == "en") {
        this.localizeRouterService.changeLanguage("en");
      } else {
        this.localizeRouterService.changeLanguage("ar");
      }
    }
  }
}
