import { Injectable } from "@angular/core";
// Integrated Google Tag Manager in an Angular app
// https://itbusinesshub.com/blog/integrate-google-tag-manager-in-angular-app
function getWindow(): any {
  return window;
}

@Injectable({
  providedIn: "root",
})
export class WindowReferenceService {
  get nativeWindow() {
    return getWindow();
  }
  constructor() {}
}
