import { Directive, ElementRef, NgZone } from "@angular/core";

@Directive({
  selector: "[adjustScroll]",
})
export class AdjustScrollDirective {


  constructor(el: ElementRef, zone: NgZone) {
    zone.runOutsideAngular(() => {
      (el.nativeElement as HTMLElement).addEventListener('scroll', (e: any) => {
        const scrollElement = Number(e.srcElement.scrollTop);
        if (scrollElement > 50) document.getElementById("navigationContainer").classList.add("header-solid");
        else document.getElementById("navigationContainer").classList.remove("header-solid");
      })
    })
  }

}
