import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { ApiService } from "src/app/core/api/api.service";

@Injectable({
  providedIn: "root",
})
export class PullBackService {
  pageName = "survey";
  surveylist = new BehaviorSubject<any>(null);
  constructor(protected api: ApiService) {}
  getSurveys() {
    return this.initSurveyList();
  }
  initSurveyList() {
    return this.api.getRequest<Object>(`${this.pageName}/surveyReasons`);
  }
  setSurvey(body) {
    return this.api.postRequest<Object>(`${this.pageName}/addInSurvey`, body);
  }
}
