import { Injectable } from "@angular/core";

import { ApiService } from "../../../core/api/api.service";
import { GlobalDAOService } from "../../../core/api/global-dao.service";

@Injectable({
  providedIn: "root",
})
export class FeedbackService extends GlobalDAOService<any> {
  constructor(protected api: ApiService) {
    super(api);
  }
  addFeedBack(body) {
    return this.api.postRequest(`survey/addInSurvey'`, body);
  }
  setAd(body) {
    return this.api.postRequest(`feedback/advertisementLead`, body);
  }
}
