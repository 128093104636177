<div class="flag-currency" *rxIf="selectedCountry">
  <mat-form-field appearance="fill">
    <mat-select (valueChange)="countryChange($event)" [(value)]="selectedCountry">
      <mat-select-trigger>
        <span class="flag-icon flag-icon-squared rounded-flag flag-icon-{{ selectedCountry.id }} "></span>
        <span> {{ "country." + selectedCountry.name | translate }} </span>
      </mat-select-trigger>
      <mat-option *rxFor="let country of countries" [value]="country">
        <span class="flag-icon flag-icon-squared rounded-flag flag-icon-{{ country.id }} "></span>
        <span> {{ "country." + country?.name | translate }} </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>