import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from "@ngx-translate/core";
@Component({
  selector: 'generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: ['./generic-button.component.scss'],
  standalone: true,
  imports: [MatIconModule, CommonModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericButtonComponent implements OnInit {
  @Input() label: string;
  @Input() matIcon: string;
  @Input() svgIcon: string;
  @Input() bindClass: string;
  @Input() color: 'primary' | 'secondary' | 'white' | 'dark-blue' | 'grey' = 'primary';
  @Input() styleType: 'flat' | 'stroked' | 'icon' = 'flat';
  @Input() radius: 'box' | 'sm-box' | 'pill' | 'circle' = 'pill'
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() href: string = null;
  @Input() target: string = null;
  @Input() disabled: boolean = false
  @Output() onClick = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }

}
