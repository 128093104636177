import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { SalesmanApiService } from "../api/salesman-api.service";
import { contactSalesManModel } from "./../contact-salesman-dialog/models/contact-salesman.model";

@Injectable({
  providedIn: "root",
})
export class SalesmanService {
  startTimer$ = new BehaviorSubject<boolean>(false);

  constructor(private SalesmanApiService: SalesmanApiService) { }

  getReservations(text) {
    return this.SalesmanApiService.getReservations(text).pipe(
      shareReplay({ refCount: true }),
      map((res) => res)
    );
  }
  getAppointments(text) {
    return this.SalesmanApiService.getAppointments(text).pipe(
      shareReplay({ refCount: true }),
      map((res) => res)
    );
  }
  getSearchCustomer(text) {
    return this.SalesmanApiService.getSearchCustomer(text).pipe(
      shareReplay({ refCount: true }),
      map((res) => res)
    );
  }

  addMeeting(data: contactSalesManModel) {
    return this.SalesmanApiService.addMeeting(data);
  }

  getOffices(devId: number) {
    return this.SalesmanApiService.getOffices(devId);
  }
  getMyPosition(data: { devId: any; appointType: number }) {
    return this.SalesmanApiService.getMyPosition(data);
  }
  removeByCustomer(data: { appointmentId: number }) {
    return this.SalesmanApiService.removeByCustomer(data);
  }
  zoomMeeting() {
    return this.SalesmanApiService.zoomMeeting();
  }
  getMeetingHistoryData() {
    return this.SalesmanApiService.getMeetingHistoryData();
  }
  getAppoitmentsDetails(appointmentID) {
    return this.SalesmanApiService.getAppoitmentsDetails(appointmentID);
  }
  getSalesmanStatus() {
    return this.SalesmanApiService.getSalesmanStatus();
  }
  callStatus(path: string) {
    return this.SalesmanApiService.callStatus(path);
  }
  callRejected(rejectedBy: string) {
    return this.SalesmanApiService.rejectCall(rejectedBy);
  }
  salesmanStatus(data: { status: number }) {
    return this.SalesmanApiService.salesManStatus(data);
  }
  endMeeting(data) {
    return this.SalesmanApiService.endMeeting(data);
  }
  startScheduledMeeting(data) {
    return this.SalesmanApiService.startScheduledMeeting(data);
  }
  noSalesManAvailable(data) {
    return this.SalesmanApiService.noSalesManAvailable(data);
  }
  userInteracted(appointmenId: number) {
    return this.SalesmanApiService.userInteracted(appointmenId);
  }
  userQuitZoom(appointmenId: number) {
    return this.SalesmanApiService.userQuitZoom(appointmenId);
  }
}
