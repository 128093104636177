import { LazyLoadingPopupComponent } from "src/app/features/lazy-loading-popup/lazy-loading-popup.component";
import { LazyLoadingPopupData } from "src/app/features/lazy-loading-popup/lazy-loading-popup.component";
import { DataLayerService } from "./../../../tag-manager/services/data-layer/data-layer.service";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { NgxHotjarService } from "ngx-hotjar";
import { Observable, Subject } from "rxjs";
import { UserModel } from "src/app/core/model/user.model";
import { OffersService } from "src/app/pages/offers/service/offers.service";

import { HeaderService } from "./header.service";
import { GeneralService } from "src/app/core/service/general.service";
import { TrackbyService } from "src/app/core/utils/trackby/trackby.service";
import { UserDAOService } from "src/app/core/api/user-dao.service";
import { UserEnum } from "src/app/core/enum/user.enum";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @Output() menuToggle = new EventEmitter();
  //#region :  declarations
  // userData: UserModel;
  language: string;
  OffersCheck: Observable<any> = this.OffersService.getOffers();
  discoverList$: Observable<any>;
  //#endregion
  userBasic;
  readonly EMPLOYEE = UserEnum.EMPLOYEE;
  //#region : constructor
  constructor(
    protected route: Router,
    protected userService: UserDAOService,
    protected headerService: HeaderService,
    private OffersService: OffersService,
    private _dataLayerService: DataLayerService,
    public hjService: NgxHotjarService,
    private dialog: MatDialog,
    private generalService: GeneralService,
    public trackedbyService: TrackbyService,
    private cdr: ChangeDetectorRef
  ) {}
  //#endregion
  // Switches language and composes a new url and sets it in the header
  changeLang(lang: string) {
    this._dataLayerService.logEvent("language", "href", "Fetched from Data Layer", "languageHref");
    this.hjService.hj("language");
    this.headerService.changeLang(lang);
    window.location.replace(`${lang}/${this.route.url.slice(4)}`);
  }

  //#region lifecycle hooks

  ngOnInit(): void {
    this.language = this.generalService.getCurrentLang();
    this.userService.userBasic$.subscribe((user) => {
      this.userBasic = user;
      this.cdr.detectChanges();
    });
    this.discoverList$ = this.headerService.getDiscoverList();
  }

  openRequestPopup() {
    const dialogData: LazyLoadingPopupData = {
      import: import("../special-request/special-request.component"),
      component: "SpecialRequestComponent",
    };
    this.dialog.open(LazyLoadingPopupComponent, { data: dialogData, panelClass: "special-request" });
  }
  //#endregion
}
