import { CurrencyService, Exchange } from 'src/app/shared/services/currency/currency.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { filter, map, Observable, take, tap } from "rxjs";

@Component({
  selector: "app-flag-dropdown-list",
  templateUrl: "./flag-dropdown-list.component.html",
  styleUrls: ["./flag-dropdown-list.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagDropDownListComponent implements OnInit {
  currencies$: Observable<Exchange[]>;
  currentExchange: Exchange = {} as Exchange;

  constructor(private currencyService: CurrencyService) { }

  ngOnInit(): void {
    this.getCurrencies();
  }

  handleChange(newCurr: string) {
    this.currencies$.pipe(take(1)).subscribe((curr) => {
      this.currentExchange = curr.find((curr) => curr.flag === newCurr);
      this.currencyService.updateCurrency(this.currentExchange);
    });
  }
  getCurrencies() {
    this.currencies$ = this.currencyService.getCurrencies().pipe(
      tap(() => {
        this.currentExchange = this.currencyService.getSelectedCurrency();
      })
    );
  }
}
