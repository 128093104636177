import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ReservationTriggerService {
  private $reservation: BehaviorSubject<any>;

  constructor() {
    this.$reservation = new BehaviorSubject(null);
  }

  setReservation(reservation) {
    this.$reservation.next(reservation);
  }

  getReservation() {
    return this.$reservation;
  }
}
