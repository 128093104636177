export const InstructionsBar = [
  {
    text: "Reserve & Pay online",
    textColor: "white",
    backgroundColor: "#FF2D2D",
    icon: undefined,
    ar: "احجز وحدتك وادفع مبلغ الحجز أونلاين",
    id: 0,
  },
  {
    text: "Browse through 9000+ units",
    textColor: "black",
    backgroundColor: "#FBB03B",
    icon: undefined,
    ar: "ابحث بين 9000 وحدة",
    id: 1,
  },
  {
    text: "Join Zoom meeting with Representative",
    textColor: "white",
    backgroundColor: "linear-gradient(to left,#23B7EC, #4A8CFF 95%)",
    icon: "zoom",
    ar: "انضم لاجتماع زووم مع مندوب المطور العقاري",
    id: 2,
  },
  {
    text: "Join WhatsApp Conversation with us",
    textColor: "white",
    backgroundColor: "linear-gradient(to left,#60D66A, #20B038 95%)",
    icon: "whatsapp",
    ar: "تحدث على واتساب مع مندوب المطور العقاري",
    id: 3,
  },
];
export interface InstructionsBarInterface {
  text: string;
  textColor: string;
  backgroundColor: string;
  icon?: string;
  ar: string;
  id: number;
}
export const InstructionsCard = [
  {
    text: "Join a <span>Zoom Meeting</span> with a representative",
    textAr: "انضم <span>لاجتماع زووم</span> مع مندوب المطور العقاري",
    headerAr: "هل تريد معرفة المزيد؟",
    header: "Need to know more?",
    backgroundColor: "#478FFD",
    icon: "zoom",
    id: 0,
  },

  {
    text: "Send to us <span>Email</span>, Browse 9000+ units",
    textAr: "أرسل إلينا<span> بريدًا إلكترونيًا</span> ، تصفح أكثر من 9000 وحدة",
    headerAr: "هل تريد معرفة المزيد؟",
    header: "Need to know more?",
    backgroundColor: "#FF2D2D",
    icon: "email",
    id: 2,
  },
  {
    text: "Attend a <span>Physical Meeting</span> with a representative",
    textAr: "حدد ميعاد <span>اجتماع مكتبي</span> مع مندوب المطور العقاري",
    headerAr: "هل تريد معرفة المزيد؟",
    header: "Need to know more?",
    backgroundColor: "#FBB03B",
    icon: "hand-shake",
    id: 3,
  },
  {
    text: "Chat via <span>What’sapp</span> with a representative",
    textAr: "تحدث على <span>واتساب</span> مع مندوب المطور العقاري",
    headerAr: "هل تريد معرفة المزيد؟",
    header: "Need to know more?",
    backgroundColor: "#22B13A",
    icon: "whatsapp",
    id: 1,
  },
];
export const InstructionsInputs = [
  {
    header: "Never miss out with our latest news, alerts and updates",
    icon: "new-email",
    inputPlaceHolder: "Add your Phone Number",
    id: 0,
    buttonText: "Submit",
    img: undefined,
  },
  {
    header: "Never miss out with our latest news, alerts and updates",
    icon: "new-email",
    inputPlaceHolder: "Add your Email Address",
    isEmail: true,
    id: 1,
    buttonText: "Submit",
    img: undefined,
  },
  {
    header: "Never miss out with our latest news, alerts and updates",
    extraText: "Need to know more?",
    inputPlaceHolder: "Add your Email Address",
    id: 2,
    isEmail: true,
    buttonText: "Submit",
    img: "assets/images/newsletter-2.webp",
  },
];
