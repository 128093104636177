import { Injectable } from "@angular/core";

import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { InPageSearch } from "src/app/core/api/in-page-search.service";
import { FilterQuery } from "src/app/shared/dto/UniqueFilter.interface";

import { OffersApiService } from "../api/offers-api.service";

@Injectable({
  providedIn: "root",
})
export class OffersService {
  offersList: Observable<any>;
  private isOffer = new BehaviorSubject(false);
  currentOffer = this.isOffer.asObservable();

  constructor(
    private offersApiService: OffersApiService,
    private SearchService: InPageSearch,
    private translateService: TranslateService
  ) {}
  changeOfferStatus(offer: boolean) {
    this.isOffer.next(offer);
  }
  getOffers() {
    if (!this.offersList)
      this.offersList = this.offersApiService.getHeader().pipe(
        map((res) => {
          return {
            LandingProjects: res.offerHeader,
            filterItems: res.filterItems,
          };
        }),
        shareReplay()
      );
    return this.offersList;
  }
  offerByCategory(cat = 3) {
    return this.offersApiService.offerByCategory(cat).pipe(
      shareReplay(),
      map((res) => {
        return {
          title:
            cat == 1 ? "offer.adminstrative" : cat == 2 ? "offer.commercial" : cat == 34 ? "offer.hotel_apartment" : "offer.residential",
          projects: res,
        };
      })
    );
  }
  getModels(project, count = 3) {
    let lang = this.translateService.currentLang;
    let searchQuery: FilterQuery = {
      config: {
        pageNumber: 1,
        itemCount: count,
        lang: lang == "en" ? 1 : 2,
        catId: 3,
      },
      query: {
        compounds_ids: [project.compId.toString()],
      },
    };
    return this.SearchService.getFilteredUnits(searchQuery).pipe(
      map((res: any) => res.data.content),
      map((res) => {
        res.forEach((element) => {
          element.status = "offer";
        });
        return res;
      })
    );
  }
}
