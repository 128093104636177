import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { UserModel } from "src/app/core/model/user.model";
import { LoadingService } from "src/app/core/service/loading.service";
@Component({
  selector: "app-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"], changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubHeaderComponent implements OnInit {
  //#region :  Input / Output
  isLoading$: Observable<Boolean>;

  constructor(private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.isLoading$ = this.loadingService.isLoading();
  }
}
