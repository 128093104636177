import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { ApiService } from "./api.service";

export class GlobalDAOService<T> {
  pageName: string = "";
  constructor(protected api: ApiService) {}

  getAll(): Observable<T> {
    return this.api.postRequest<T>(`${this.pageName}/getall/`, {});
  }

  getOne(newData: any): Observable<T> {
    return this.api.postRequest<T>(`${this.pageName}/getone/`, newData);
  }

  find(data: any, pageSize: number, pageNumber: number, sortedBy: string, order: string, filter: any): Observable<Object> {
    data = Object.assign({}, data);
    data["page_number"] = pageSize + "";
    data["number_objects"] = pageNumber + "";
    if (filter) data["filter"] = filter;
    return this.api.postRequest<Object>(`${this.pageName}/find`, data).pipe(shareReplay());;
  }

  findAll(data: any, filter: any): Observable<Object> {
    data = Object.assign({}, data);
    data["filter"] = filter;
    return this.api.postRequest<Object>(`${this.pageName}/findAll`, data).pipe(shareReplay());;
  }
}
