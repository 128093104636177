import { LogLevel } from "src/app/core/utils/logger/enum/log-level/log-level.enum";

export const environment = {
  production: true,
  // hotjar: "3012216",
  appVersion: "0.0.1",
  hotjar: "3105542",
  ipAddress: "https://jsonip.com",
  mapTilesUrl: "https://map-location.s3.eu-de.cloud-object-storage.appdomain.cloud",
  backendUrl: "/api",
  paymentUrl: "/apiPlan",
  zoomUrl: "https://dev-zoom.k8s-cluster-poc-475abba301f29ce035eb2a3d8e891717-0000.eu-de.containers.appdomain.cloud/",
  bankUrl: (<any>window)["env"]["bankUrl"],
  unitUrl: "/apiUnit",
  staticUrl: "",
  localMediaUrl: "https://vso-media.ecommerce.tmg.com.eg", //Change to 8081 when using externally hosted assets
  chatBotUrl: "/apiChatbot",
  socketIoUrl: "",
  recaptcha: {
    siteKey: "6Le-7YogAAAAAOBi5tw62SqWfs0z_S-vOQmYtEJy",
  },
  chatBotIntegerationId: (<any>window)["env"]["chatbotId"], //"32b667ad-3c5d-49fb-b1bc-634f27dd57d3",
};
