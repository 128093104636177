import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { ApiService } from "src/app/core/api/api.service";
import { GlobalDAOService } from "src/app/core/api/global-dao.service";

import { contactSalesManModel } from "./../contact-salesman-dialog/models/contact-salesman.model";

@Injectable({
  providedIn: "root",
})
export class SalesmanApiService extends GlobalDAOService<any> {
  constructor(protected api: ApiService) {
    super(api);
  }
  getReservations(text): Observable<any> {
    return this.api.getRequest<any>(`salesman/getSalesManReservations?keyWord=${text}`).pipe(shareReplay());
  }
  getAppointments(text) {
    return this.api.getRequest<any>(`salesman/SalesManAppointments?keyWord=${text}`).pipe(shareReplay());
  }
  getSearchCustomer(text) {
    return this.api.getRequest<any>(`salesman/getSalesManCustomers?keyWord=${text}`).pipe(shareReplay());
  }
  addMeeting(data: contactSalesManModel) {
    return this.api.postRequest<any>("appointments", data).pipe(shareReplay());
  }
  getOffices(devId: number) {
    return this.api.getRequest<any>(`devOffice?devId=${devId}`).pipe(shareReplay());
  }
  getMyPosition(data: { devId: any; appointType: number }) {
    if (data.devId != "") {
      return this.api.getRequest<any>(`appointments/getMyPositionNew?devId=${data.devId}&appointType=${data.appointType}`);
    } else {
      return this.api.getRequest<any>(`appointments/getMyPositionNew?appointType=${data.appointType}`);
    }
  }
  removeByCustomer(data: { appointmentId: number }) {
    return this.api.getRequest<any>(`appointments/removeByCustomer?appointId=${data.appointmentId}`);
  }
  zoomMeeting() {
    return this.api.postRequest<any>("salesmen/Zoom/createMeeting", {});
  }
  getMeetingHistoryData() {
    return this.api.getRequest<any>("meetingConfig/meetingHistory/config");
  }
  getAppoitmentsDetails(appointmentID: string) {
    return this.api.getRequest<any>(`salesman/salesmanAppointmentDetailsInfo?appointmentId=${appointmentID}`);
  }
  getSalesmanStatus() {
    return this.api.getRequest<any>("salesman/status");
  }
  callStatus(path: string) {
    return this.api.postRequest<any>(`appointmentManage/${path}`, {});
  }
  rejectCall(rejectBy: string) {
    return this.api.postRequest<any>(`appointmentManage/reject?rejectedBy=${rejectBy}`, {});
  }
  salesManStatus(data) {
    return this.api.postRequest<any>("salesmanManage/status", data);
  }
  endMeeting(data) {
    return this.api.postRequest<any>("appointments/end", data);
  }
  startScheduledMeeting(appointId: string) {
    return this.api.postRequest<any>(`appointmentManage/scheduleToCall?appointmentId=${appointId}`, {});
  }
  noSalesManAvailable(data) {
    return this.api.postRequest("appointments/noSalesmanAvailable", data);
  }
  userInteracted(appointmentId: number) {
    return this.api.getRequest(`appointments/userInteracted?interacted=true&appointmentId=${appointmentId}`);
  }
  userQuitZoom(appointmentId: number) {
    return this.api.getRequest(`appointments/userQuitZoom?appointmentId=${appointmentId}`);
  }
}
