<footer>
  <section class="footer">
    <section class="footer__top">
      <section class="footer__top__logo" [routerLink]="'/' | localize">
        <img alt="" [src]="lang == 'en' ? 'assets/logo/eshtri-footer.svg' : 'assets/logo/eshtri.aqar-14.svg'"
          style="height: 7.1rem; width: 16rem" loading="lazy" />
        <p class="footer__top__logo-desc">{{ "home.head.headline" | translate }}</p>
        <div class="img-footer">
          <a href="https://apps.apple.com/eg/app/eshtri-aqar-real-estate/id6449798338"
            title="eshtri aqar app store"><mat-icon svgIcon="apple-store"></mat-icon></a>
          <a href="https://play.google.com/store/apps/details?id=com.EshtriAqar&pli=1"
            title="eshtri aqar play store"><mat-icon svgIcon="play-store"></mat-icon></a>
        </div>
      </section>

      <section class="footer__top__social">
        <p>{{ "footer.get_touch" | translate }}</p>
        <section>
          <p class="call">
            <a href="tel:16407" [ngClass]="{ row: lang == 'en', 'row-reverse': lang == 'ar' }">
              <mat-icon svgIcon="phone-filled" class="svgcall"></mat-icon>
              {{ "aboutUs.16407" | translate }}
            </a>
          </p>
          <div class="social">
            <figure>
              <a href="https://www.facebook.com/EshtriAqar" title="facebook" target="_blank">
                <mat-icon class="social-icon" svgIcon="facebook"></mat-icon>
              </a>
            </figure>
            <figure>
              <a href="https://www.instagram.com/eshtri_aqar/" title="instagram" target="_blank">
                <mat-icon class="social-icon" svgIcon="instagram"></mat-icon>
              </a>
            </figure>

            <figure>
              <a href="https://www.linkedin.com/company/eshtri-aqar/?viewAsMember=true" title="linkedin"
                target="_blank">
                <mat-icon class="social-icon" svgIcon="linkedin"></mat-icon>
              </a>
            </figure>
            <figure>
              <a href="https://www.youtube.com/channel/UC5Y5BHLbvPJJRHTIXJcxw7g" title="youtube" target="_blank">
                <mat-icon class="social-icon" svgIcon="youtube"></mat-icon>
              </a>
            </figure>
          </div>
        </section>
      </section>
    </section>

    <section class="footer__top__prop">
      <div class="footer__top__prop__trusted">
        <p class="trusted">{{ "footer.trustedBy" | translate }}</p>

        <mat-icon svgIcon="bank-ahly" class="bank"></mat-icon>
        <img height="36" width="88" src="assets/bankMasr.png" alt="bank masr">
        <mat-icon svgIcon="fawry" class="bank">
        </mat-icon>
      </div>
    </section>

    <div class="footer__bottom">
      <div>
        <ul class="footer__bottom__terms">
          <li>
            <button class="bootom__underline" (click)="terms()">{{ "footer.Terms_Conditions" | translate }}</button>
          </li>
          <li>
            <button class="bootom__underline" (click)="policy()">{{ "footer.Privacy_Policy" | translate }}</button>
          </li>
          <li>
            <button class="bootom__underline" (click)="faq()">{{ "footer.Faqs" | translate }}</button>
          </li>
        </ul>
      </div>
      <div class="footer__bottom__copyrights">
        <p>{{ "footer.copyrights_reserved" | translate }} @{{ year }}</p>
      </div>

      <div class="footer__bottom__incubated">
        <p>{{ "footer.incubated_by" | translate }} <a target="_blank" href="http://www.cic.ae/">CIC (Certified IT
            Consultants)</a></p>
      </div>
    </div>
  </section>
</footer>