import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { GeneralService } from "../../../service/general.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"], changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  lang: string;
  lol: any
  constructor(private generalService: GeneralService, private router: Router) { }
  ngOnInit(): void {
    this.lang = this.generalService.getCurrentLang();
  }
  terms() {
    this.router.navigate([`${this.lang}/term`]);
  }
  policy() {
    this.router.navigate([`${this.lang}/policy`]);
  }
  faq() {
    this.router.navigate([`${this.lang}/faqs`]);
  }

}
