import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TrackbyService {
  constructor() {}

  identifier(item: any) {
    return item.id;
  }
}
