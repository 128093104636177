import { InstructionsBarInterface, InstructionsBar as instructions } from "./../../../model/instructions";
import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
// import { ContactSalesmanDialogComponent } from "";
import { FeedbackService } from "src/app/shared/services/feedback/feedback.service";
import { Subscription } from "rxjs";
import { GeneralService } from "src/app/core/service/general.service";
import { LazyLoadingPopupComponent, LazyLoadingPopupData } from "src/app/features/lazy-loading-popup/lazy-loading-popup.component";

@Component({
  selector: "app-instruction-bar",
  templateUrl: "./instruction-bar.component.html",
  styleUrls: ["./instruction-bar.component.scss"], changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstructionBarComponent implements OnInit, OnDestroy {
  @Output() closeAction = new EventEmitter();

  readonly eshtri_whatsapp = +201000015304;

  selectedBar: InstructionsBarInterface;
  lang: string;
  activeURL = this.route.url;
  subscription: Subscription;

  constructor(
    private generalService: GeneralService,
    private dialog: MatDialog,
    private route: Router,
    private feedBackService: FeedbackService
  ) {
    this.updateSelectedBar();
    this.lang = this.generalService.getCurrentLang();
  }

  ngOnInit(): void {
    this.subscribeNavigationEndRoute();
  }
  subscribeNavigationEndRoute() {
    this.subscription = this.route.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((e: NavigationEnd) => {
      this.activeURL = e.urlAfterRedirects.split("?")[0].slice(3);
      this.updateSelectedBar();
    });
  }
  updateSelectedBar() {
    const filteredInstructions = instructions.filter((i) => i.id !== this.selectedBar?.id);
    this.selectedBar = filteredInstructions[Math.floor(Math.random() * filteredInstructions.length)];
  }

  clickAction(action) {
    if (action.id == 2) {
      const dialogData: LazyLoadingPopupData = {
        import: import('src/app/sales-man/contact-salesman-dialog/contact-salesman-dialog.component'), component: 'ContactSalesmanDialogComponent', data: {
          developerName: this.lang != "en" ? "اشتري عقار" : " Eshtri Aqar",
          tab: "zoom",
          developerId: "",
          servay: true,
          schedule: true,
        }
      };
      this.dialog.open(LazyLoadingPopupComponent, { data: dialogData, panelClass: "contact-salesman-dialog-container" });

      // this.dialog.open(ContactSalesmanDialogComponent, {
      //   panelClass: "contact-salesman-dialog-container",
      //   data:,
      // });
    } else if (action.id == 3) {
      window.open("//wa.me/" + this.eshtri_whatsapp, "_blank");
    } else if (action.id == 1) {
      this.route.navigateByUrl(`${this.lang}/listings`);
    }
    if (this.activeURL == "") this.setAd("", "home", action.text);
    else {
      this.setAd("", this.activeURL, action.text);
    }
  }
  setAd(leadValue: string, page: string, adText: string) {
    let body = { leadValue, page, adText };
    this.feedBackService.setAd(body).subscribe((res) => { });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
