import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AdjustScrollDirective } from "../directive/adjust-scroll.directive";
import { MainLayoutComponent } from "./main-layout/main-layout.component";
import { CountriesDropdownComponent } from "./components/countries-dropdown/countries-dropdown.component";
import { HeaderComponent } from "./components/header/header.component";
import { InstructionBarComponent } from "./components/instruction-bar/instruction-bar.component";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { LocalizeRouterModule } from "@gilsdav/ngx-translate-router";
import { TranslateModule } from "@ngx-translate/core";
import { GenericButtonComponent } from "src/app/shared/@generics/generic-button/generic-button.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { FlagDropDownListComponent } from "./components/flag-dropdown-list/flag-dropdown-list.component";
import { RegisterNavItemComponent } from "./components/register-nav-item/register-nav-item.component";
import { SubHeaderComponent } from "./components/sub-header/sub-header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MatOptionModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { IfModule } from '@rx-angular/template/if'
import { ForModule } from '@rx-angular/template/for'
import { LetModule } from '@rx-angular/template/let'
@NgModule({
  declarations: [
    AdjustScrollDirective,
    RegisterNavItemComponent,
    FooterComponent,
    FeedbackComponent,
    InstructionBarComponent,
    FlagDropDownListComponent,
    SubHeaderComponent,
    HeaderComponent,
    CountriesDropdownComponent,
    MainLayoutComponent,
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    TranslateModule,
    RouterModule,
    LocalizeRouterModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    GenericButtonComponent,
    MatSidenavModule,
    MatIconModule,
    MatProgressBarModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    MatOptionModule,
    MatRadioModule,
    IfModule,
    ForModule,
    LetModule
  ],
  exports: [MainLayoutComponent, FeedbackComponent],
})
export class MainLayoutModule { }
