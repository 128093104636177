<!-- <ngx-spinner
  bdColor="rgba(255,255,255)"
  size="small"
  [fullScreen]="true"
  type="none"
  #spinner
  template="
<div class='loading-logo'>
<img src='assets/svg/Group 2934.svg' loading='lazy'/>
<div class='bar' id='bar'> </div> </div>"
></ngx-spinner> -->
<main-layout></main-layout>