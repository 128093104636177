<div class="progress-bar">
  <mat-progress-bar *rxIf="isLoading$; patchZone:false" mode="indeterminate"> </mat-progress-bar>
</div>

<nav class="nav__container">
  <!-- NAVIGATION SECTION -->
  <ul class="nav__elements">
    <li class="nav__element">
      <app-flag-dropdown-list></app-flag-dropdown-list>
    </li>
    <li class="nav__element">
      <a class="nav__item" routerLink="{{'/about-us' | localize}}" routerLinkActive="active">{{ 'navbar.about' |
        translate }}</a>
    </li>
    <register-nav-item></register-nav-item>
    <li class="nav__element">
      <a class="nav__item" routerLink="{{'/contact-us' | localize}}" routerLinkActive="active">{{ 'navbar.contact' |
        translate }}</a>
    </li>
  </ul>
  <!-- END OF NAVIGATION SECTION -->
</nav>