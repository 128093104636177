import { MatDialog } from "@angular/material/dialog";
import { HeaderService } from "./../header/header.service";
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { Router } from "@angular/router";
import { LocalizeRouterService } from "@gilsdav/ngx-translate-router";
import { Observable, take } from "rxjs";
import { UserDAOService } from "src/app/core/api/user-dao.service";
import { UserEnum } from "src/app/core/enum/user.enum";

@Component({
  selector: "register-nav-item",
  templateUrl: "./register-nav-item.component.html",
  styleUrls: ["./register-nav-item.component.scss"], changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterNavItemComponent implements OnInit {
  @Output() afterloginTriggerClose = new EventEmitter();
  userBasic$: Observable<any>;
  readonly GUEST = UserEnum.GUEST
  constructor(
    private headerService: HeaderService,
    private localizeService: LocalizeRouterService,
    private route: Router,
    private dialog: MatDialog,
    private userService: UserDAOService
  ) { }

  ngOnInit(): void {
    this.userBasic$ = this.userService.userBasic$
  }

  loginSignupTrigger() {
    // const dialogData: LazyLoadingPopupData = { import: import('src/app/features/test/test.component'), component: 'TestComponent' }
    // this.dialog.open(LazyLoadingPopupComponent, { data: { import: import('src/app/features/test/test.component'), componentName: 'TestComponent' } })
    this.headerService
      .loginSignupTrigger()
      .afterClosed()
      .pipe(take(1))
      .subscribe((res) => {
        if (res) this.afterloginTriggerClose.emit();
      });
  }

  logOut() {
    this.headerService.logOut();
  }

  navigateToProfile() {
    if (this.userService.userBasic?.userType == UserEnum.EMPLOYEE) {
      let Route = this.localizeService.translateRoute("/salesman-profile");
      this.route.navigate([`/${Route}`]);
    } else {
      let Route = this.localizeService.translateRoute("/profile");
      this.route.navigate([`/${Route}`]);
    }
  }
}
