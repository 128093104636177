<li class="action__buttons nav__element" *rxLet="userBasic$; let userBasic">
  <!-- LOGIN/REGISTER BUTTON -->
  <button (click)="loginSignupTrigger()" *rxIf="!userBasic || userBasic.userType ==GUEST" class="action__btn nav__item">
    <span>{{ "navbar.login_register" | translate }}</span>
  </button>
  <!-- END OF LOGIN/REGISTER BUTTON -->

  <!-- MY ACCOUNT BUTTON  -->
  <button *rxIf="userBasic.userType !=GUEST" [matMenuTriggerFor]="menu" class="nav__item action__btn">
    <span class="user__name">{{ "navbar.my_account" | translate }}</span>
    <mat-icon>expand_more</mat-icon>
    <mat-menu #menu="matMenu" xPosition="before" class="headerMenu">
      <button (click)="navigateToProfile()" mat-menu-item aria-label="Justify">
        <mat-icon> account_circle</mat-icon>
        {{ "header.menu.myAccount" | translate }}
      </button>
      <button (click)="logOut()" mat-menu-item aria-label="Justify">
        <mat-icon>logout</mat-icon>
        {{ "header.menu.logOut" | translate }}
      </button>
    </mat-menu>
  </button>
</li>