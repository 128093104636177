import { Pipe, PipeTransform } from "@angular/core";
import { StorageService } from "src/app/core/service/utilities/local-storage/storage.service";

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  transform(number: any, args?: any, rate?: number): any {
    if (isNaN(number)) return null;
    if (number === null) return null;
    if (number === 0) return 0;
    if (!rate) rate = 1;
    number *= rate;
    number = Math.round(number * 100) / 100;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0;
    let key = "";

    const powers = [
      { key: "Q", value: Math.pow(10, 15) },
      { key: "T", value: Math.pow(10, 12) },
      { key: "B", value: Math.pow(10, 9) },
      { key: "M", value: Math.pow(10, 6) },
      { key: "K", value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    // let fixed = Math.round(abs * 100) / 100;

    return (isNegative ? "-" : "") + abs + key;
  }
}
