import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { AppInitService } from "./core/app-init.service";
import { UnitDetailsResolver } from "./pages/project-details/resolver/unitDetails-resolver.service";
import { ShortNumberPipe } from "./shared/modules/dynamic-search/pipes/short-number.pipe";
import { DatePipe } from "@angular/common";
import { MatNativeDateModule } from "@angular/material/core";

function loadConfigFactory(AppInitService: AppInitService) {
  return () => AppInitService.init();
}
@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CoreModule, AppRoutingModule, MatNativeDateModule],
  providers: [ShortNumberPipe, DatePipe,
    UnitDetailsResolver,
    Meta,
    Title,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider("302076448990-frlkbnej97djj1e1g1ofkfva8a787h8p.apps.googleusercontent.com", {
              scope: "email",
              plugin_name: "eshtriAQAR",
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("422488576152803"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfigFactory,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  // providers: [PopupService],
})
export class AppModule { }
