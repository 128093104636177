import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { take, tap } from "rxjs";
import { HomeService } from "src/app/pages/home/service/home.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor(private homeService: HomeService, private router: Router) { }

  init() {
    const home_route = ['/en', '/en/', '/ar', '/ar/']
    if (home_route.includes(location.pathname))
      return this.homeService.getPromoHeader().pipe(take(1), tap((res: any) => {
        const link = document.createElement('link')
        link.href = res.LandingProjects[0].img + '.webp'
        link.rel = 'prefetch'
        document.head.appendChild(link)
      }))

  }

}
