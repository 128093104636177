<ng-container *ngIf="!href; else aElement">
  <button
    class="gbtn gbtn-{{ styleType }} {{ color }}  {{ radius }}"
    [ngClass]="bindClass ?? ''"
    (click)="onClick.emit($event)"
    [type]="type"
    [class.disabled]="disabled"
    [disabled]="disabled"
  >
    <mat-icon class="icon" *ngIf="matIcon" [fontIcon]="matIcon"></mat-icon>
    <mat-icon class="icon" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
    <span *ngIf="label">{{ label | translate }}</span>
  </button>
</ng-container>
<ng-template #aElement>
  <a
    [href]="href"
    [target]="target"
    class="gbtn gbtn-{{ styleType }} {{ color }}  {{ radius }}"
    [ngClass]="bindClass ?? ''"
    (click)="onClick.emit($event)"
    [class.disabled]="disabled"
  >
    <span class="gbtn-content">
      <mat-icon class="icon" *ngIf="matIcon" [fontIcon]="matIcon"></mat-icon>
      <mat-icon class="icon" *ngIf="svgIcon" [svgIcon]="svgIcon"></mat-icon>
      <span *ngIf="label">{{ label | translate }}</span>
    </span>
  </a>
</ng-template>
