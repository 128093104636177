import { GeneralService } from "../../../service/general.service";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { WindowReferenceService } from "../window-reference/window-reference.service";
import { UserDAOService } from "src/app/core/api/user-dao.service";

@Injectable({
  providedIn: "root",
})
// Integrated Google Tag Manager in an Angular app
// https://itbusinesshub.com/blog/integrate-google-tag-manager-in-angular-app
export class DataLayerService {
  helper = new JwtHelperService();
  private window;
  private lang: string;
  constructor(private _windowRef: WindowReferenceService, private generalService: GeneralService, private userService: UserDAOService) {
    this.window = _windowRef.nativeWindow; // intialise the window to what we get from our window service

    this.lang = this.generalService.getCurrentLang();
  }


  private pingHome(obj: any) {
    if (obj) this.window.dataLayer?.push(obj);
  }

  //list of all our dataLayer methods

  logPageView(url: any): void {

    const hit = {
      event: "content-view",
      pageName: url,
      user_id: this.userService.userBasic?.id,
      user_type: this.userService.userBasic?.userType,
    };
    this.pingHome(hit);
  }

  logEvent(event: any, category: any, action: any, label: any, click?: any, clickId?: any) {
    const hit = {
      event: event,
      category: category,

      label: label,
      language: this.lang,
      click: click,
      clickId: clickId,
      user_id: this.userService.userBasic?.id,
      user_type: this.userService.userBasic?.userType,
    };

    this.pingHome(hit);
  }
  logEventSalesMan(event: any, category: any, action: any, label: any, click?: any, developerName?: any, clickId?: any) {
    let token = localStorage.getItem("token");
    let decodedToken = this.helper.decodeToken(token);
    const hit = {
      event: event,
      category: category,
      user_id: decodedToken == null ? null : decodedToken.jti,
      label: label,
      language: this.lang,
      click: click,
      developerName: developerName,
      clickId: clickId,
    };

    this.pingHome(hit);
  }

  logCustomDimensionTest(value: any) {
    const hit = {
      event: "custom-dimension",
      value: value,
    };
    this.pingHome(hit);
  }

  // .. add more custom methods as needed by your app.
}
