import { Observable } from "rxjs";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Unit } from "src/app/shared/dto/Unit.interface";
import { UnitDTOService } from "src/app/core/api/unit-dto.service";

@Injectable({
  providedIn: "root",
})
export class UnitDetailsResolver implements Resolve<Unit> {
  constructor(private unitDTOService: UnitDTOService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Unit | Observable<Unit> | Promise<Unit> {
    let unitId = route.params.unitid.toString().split("-");
    return this.unitDTOService.getUnitinfo(+unitId[0]);
  }
}
