import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { shareReplay } from "rxjs/internal/operators/shareReplay";
import { ApiService } from "src/app/core/api/api.service";
import { GlobalDAOService } from "src/app/core/api/global-dao.service";
import { StorageService } from "src/app/core/service/utilities/local-storage/storage.service";
declare function emarsysCurrency(selectedCurrency): void;
@Injectable({
  providedIn: "root",
})
export class CurrencyService extends GlobalDAOService<any> {
  readonly page = "currency";

  private _currencies$: Observable<Exchange[]>;

  private _selectedCurrency$ = new BehaviorSubject<Exchange>(null);
  public selectedCurrency$ = this._selectedCurrency$.asObservable();

  constructor(protected api: ApiService, private storageService: StorageService) {
    super(api);
    this.setCurrencies();
  }

  private setCurrencies() {
    this._currencies$ = this.api.getRequest<any>(`${this.page}/exchange`).pipe(
      map((currency: any) => {
        let currencies = Object.entries(currency.conversion_rates).map((e) => this.currencyGenerator(e[0], e[1] as string));

        let selectedValue = JSON.parse(this.storageService.getItem("selectCurrency"));
        if (!selectedValue) {
          selectedValue = currencies[0];
          this.updateLocalStorage(selectedValue);
        }
        this._selectedCurrency$.next(selectedValue);
        return currencies;
      }),
      shareReplay(1)
    );
  }

  private currencyGenerator(name: string, value: string) {
    return { flag: name.slice(0, 2).toLowerCase(), name, value };
  }
  getCurrencies(): Observable<Exchange[]> {
    return this._currencies$;
  }
  updateLocalStorage(value) {
    this.storageService.setItem("selectCurrency", JSON.stringify(value));
  }

  updateCurrency(value: Exchange) {
    this._selectedCurrency$.next(value);
    this.updateLocalStorage(value);
    emarsysCurrency(value.name);
  }
  getSelectedCurrency(): Exchange {
    return this._selectedCurrency$.getValue();
  }
}

export class Exchange {
  flag: string;
  name: any;
  value: string;
}
