import { DOCUMENT } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
// import { NavigationEnd, NavigationStart, Router } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { NgxHotjarService } from "ngx-hotjar";
// import { NgxSpinnerService } from "ngx-spinner";
import { UserDAOService } from "./core/api/user-dao.service";
// import { ChatbotService } from "./core/layout/components/chatbot/chatbot.service";
import { DataLayerService } from "./core/tag-manager/services/data-layer/data-layer.service";
import { SocketService } from "./sales-man/service/socket.service";
import { UserEnum } from "./core/enum/user.enum";
import { iconsList, cs_icons } from "src/assets/icons.js";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: string | null = "ar";
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document,
    private userDAO: UserDAOService,
    //  private chatbotService: ChatbotService,
    private socketService: SocketService,
    // private _router: Router,
    // private spinner: NgxSpinnerService,
    private _dataLayerService: DataLayerService,
    public hjService: NgxHotjarService
  ) {
    console.log("v3");

    setTimeout(() => {
      const hotjarScript = document.createElement("script");
      hotjarScript.type = "text/javascript";
      hotjarScript.innerText = ` (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = {
        hjid: 3012216,
        hjsv: 6,
      };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");`;
      document.body.appendChild(hotjarScript);
    }, 5000);
    // (window as any).Intercom("boot", {
    //   api_base: "https://api-iam.intercom.io",
    //   app_id: "ftv8r76l",
    // });

    // this._dataLayerService.logEvent("userData", "Landing", "Fetched from Data Layer", "LoginLanding");
    // this.spinner.show();
    // let timeOut = setTimeout(() => {
    //   this.spinner.hide();
    // }, 900);
    // this._router.events.subscribe((event) => {
    // (window as any).Intercom("update");
    // if (event instanceof NavigationStart) {
    //   if (!this._router.url.includes("listings?") && !this._router.url.includes("properties?")) {
    //     clearTimeout(timeOut);
    //     // this.spinner.show();
    //   }
    // }
    // subscribe to router events
    // if (event instanceof NavigationEnd) {
    //   timeOut = setTimeout(() => {
    //     this.spinner.hide();
    //   }, 900);
    //   //   //if our event is of our interest
    //   //   // this._dataLayerService.logPageView(event.url); //call our dataLayer service's page view method to ping home with the url value.
    // }
    // });
    iconsList.forEach((icon) => {
      iconRegistry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.url));
    });
    //  iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl("assets/bath.svg"));
    this.translateService.stream("DIR").subscribe((dir) => {
      console.log("dirchange", dir);

      this.directionChanged(dir);
    });
    this.userDAO?.autoLogin();

    this.userDAO.userBasic$.subscribe((user) => {
      console.log(user);
      const chatScript = document.createElement("script");

      if (!user) {
        this.userDAO.getUser().subscribe();
        return;
      }
      if (user?.userType == UserEnum.EMPLOYEE) {
        this.socketService.connect(this.userDAO.userBasic.salesRepId);
        this.socketService.isUserSalesMan$.next(true);
        const bot = document.body.getElementsByClassName("widget-visible");
        if (bot[0]) {
          document.body.removeChild(bot[0]);
        }
      } else {
        chatScript.type = "text/javascript";
        chatScript.innerText = `var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();

    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];

      s1.async = true;

      s1.src = "https://embed.tawk.to/6422ebf931ebfa0fe7f523df/1gsk6ji23";

      s1.charset = "UTF-8";

      s1.setAttribute("crossorigin", "*");

      s0.parentNode.insertBefore(s1, s0);
    })();`;
        document.body.appendChild(chatScript);
      }
    });
  }
  ngAfterViewInit(): void {
    const shell = document.getElementById('shell') as HTMLElement;
    shell.remove()
  }
  private directionChanged(dir: string): void {
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = dir === "rtl" ? "rtl" : "ltr";
    htmlTag.lang = dir === "rtl" ? "ar" : "en";
  }

  ngOnInit(): void {
    this._dataLayerService.logEvent("userData", "Landing", "Fetched from Data Layer", "LoginLanding");
    this.hjService.hj("userData");
  }

  ngOnDestroy(): void { }
}
